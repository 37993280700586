import React, { useEffect } from 'react';
import type { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import BlogContainer from '../src/containers/listBlog/blog';
import { createRequest } from '../src/utils/request';
import { configs } from '../src/configs';
import { useRouter } from 'next/router';
import { dehydrate, QueryClient, useQuery } from 'react-query';
import smoothscroll from 'smoothscroll-polyfill';

const Home: NextPage = () => {
  const router = useRouter();
  const { page = '1' } = router.query;
  const { data: rq_data, isFetching } = useQuery(
    ['getListBlog', page],
    () => getListBlog(page),
    { refetchOnWindowFocus: false }
  );
  useEffect(() => {
    smoothscroll.polyfill();
    window.scroll({ top: 0, behavior: 'smooth' });
  }, [page]);
  return (
    <>
      <Head>
        <title>Telio | Blog</title>
        <meta name='description' content='Telio | Blog' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <link rel='icon' href='/icons/icon-48x48.png' />

        <meta property='og:title' content='Telio | Blog' />
        <meta property='og:type' content='Blog' />
        <meta
          property='og:image'
          content='/images/bannerMobileBackground.png'
        />
        {/* <meta property="og:url" content="https://merakiconsumer.com" /> */}
        <meta
          name='twitter:card'
          content='/images/bannerMobileBackground.png'
        />

        <meta property='og:description' content='Telio Blog' />
        <meta property='og:site_name' content='Telio Blog' />
        <meta name='twitter:image:alt' content='Telio Blog' />
        <meta
          name='google-site-verification'
          content='g3yjwKP-M7dNyYSxyv_fO_9CbXbpvWq4HVssfPmv4tU'
        />
      </Head>

      <main>
        <BlogContainer
          listArticles={rq_data?.data?.listArticles}
          totalPage={rq_data?.data?.totalPage}
          currentPage={page || '1'}
          isFetching={isFetching}
        />
      </main>
    </>
  );
};
export const getListBlog = async (
  page: string | number | string[] | undefined
) => {
  return createRequest(
    `${configs.apiBlogEndpoint}/v1/articles/list?pageSize=9&pageNumber=${
      page || 1
    }&status=show`,
    {
      method: 'GET',
    }
  );
};
export const getStaticProps: GetStaticProps = async () => {
  const page = '1';
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(['getListBlog', page], () =>
    getListBlog(page)
  );
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 180,
  };
};

// export const getServerSideProps: GetServerSideProps = async ({ query }) => {
//   // old function
//   const { page } = query;
//   const data = await getListBlog(page);
//   return {
//     props: { data: data?.data },
//   };
// };
export default Home;
