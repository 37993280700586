import React from 'react';
import styles from './styles.module.css';
import { WrapImage } from '../wrapImage';
import moment from 'moment';
import CustomeLink from '../CustomLink';

type BlogCardProps = {
  title: string;
  description: string;
  createdAt: string;
  cover_url: string;
  id: string;
  slug: string;
};
const Blog = ({
  title,
  description,
  createdAt,
  cover_url,
  slug,
}: BlogCardProps) => {
  return (
    <div className={styles.blogBox}>
      <CustomeLink href={`/blog/${slug}`}>
        <div className={styles.blog}>
          <WrapImage
            desktop={{
              src: cover_url,
              alt: 'blog image',
              objectFit: 'cover',
              layout: 'fill',
              className: styles.blogImage,
            }}
          />
          <h2 className={styles.blog_headding} title={title}>
            {title}
          </h2>
          <p className={styles.blog_time}>
            {moment(createdAt).format('HH:MM DD/MM/YYYY')}
          </p>
          <p className={styles.blog_content}>{description}</p>
        </div>
      </CustomeLink>
    </div>
  );
};
export default Blog;
