import React from 'react';
import Blog from '../../components/Blog';
import FixedHeaderMobile from '../../components/fixedHeaderMobile';
// import Footer from '../../components/footer';
// import FooterBanner from '../../components/footerBanner';
// import Header from '../../components/header';
import { WrapImage } from '../../components/wrapImage';
import { useWindowWidth } from '../../hooks/useWindowWidthHook';
import styles from './styles.module.css';
import CustomeLink from '../../components/CustomLink';
import dynamic from 'next/dynamic';
import { T } from '../../hooks/translation';
const DynamicHeader = dynamic(() => import('../../components/header'));
const DynamicFooterBanner = dynamic(
  () => import('../../components/footerBanner'),
  { ssr: false }
);
const DynamicFooter = dynamic(() => import('../../components/footer'));

type BlogContainerProps = {
  listArticles: any[];
  currentPage: string | string[] | undefined;
  totalPage: string | undefined;
  isFetching?: boolean;
};

function BlogContainer({
  listArticles = [],
  currentPage = '1',
  totalPage = '1',
}: BlogContainerProps) {
  const width = useWindowWidth();
  const disablePrevButton = React.useMemo(() => {
    if (Number(currentPage) - 1 === 0) {
      return true;
    }
    return false;
  }, [currentPage]);
  const disableNextButton = React.useMemo(() => {
    if (Number(currentPage) + 1 > Number(totalPage)) {
      return true;
    }
    return false;
  }, [currentPage, totalPage]);

  return (
    <div className={styles.wrapBlogContainer}>
      {width > 768 && (
        <>
          <DynamicHeader />
        </>
      )}
      {width <= 768 && (
        <>
          <FixedHeaderMobile />
        </>
      )}
      <div>
        <div className={styles.bannerBackground}>
          <WrapImage
            desktop={{
              src: '/images/bannerBackground.png',
              alt: 'banner-background logo',
              layout: 'fill',
              objectFit: 'cover',
              priority: true,
              style: {
                width: '100%',
                height: '498px',
                position: 'relative',
                display: 'block',
              },
            }}
            mobile={{
              src: '/images/bannerMobileBackground.png',
              alt: 'banner-background logo',
              layout: 'fill',
              objectFit: 'cover',
              priority: true,
              style: {
                width: '100%',
                height: '307px',
                position: 'relative',
                display: 'block',
              },
            }}
          />
        </div>
        <div className={styles.body}>
          <div className={styles.listBlogBox}>
            <div className={styles.body_listBlog}>
              {(listArticles || []).map((blog, i) => {
                return (
                  <Blog
                    key={i}
                    title={blog?.title}
                    description={blog?.description}
                    createdAt={blog?.createdAt}
                    cover_url={blog?.cover?.photo_url}
                    id={blog?._id}
                    slug={blog?.slug_title}
                  />
                );
              })}
            </div>

            {listArticles.length != 0 && (
              <div className={styles.transferPage}>
                <CustomeLink
                  href={`?page=${Number(currentPage) - 1}`}
                  disable={disablePrevButton}
                >
                  <WrapImage
                    desktop={{
                      src: '/images/moveLeft.png',
                      alt: 'moveLeft-logo',
                      width: 48,
                      height: 48,
                      className: `${styles.transferButton} ${
                        disablePrevButton && styles.block
                      } `,
                    }}
                  />
                </CustomeLink>
                <div className={styles.transferPage_pageNumber}>
                  {T('trang')} {currentPage}/{totalPage}
                </div>
                <CustomeLink
                  href={`?page=${Number(currentPage) + 1}`}
                  disable={disableNextButton}
                >
                  <WrapImage
                    desktop={{
                      src: '/images/moveRight.png',
                      alt: 'moveRight-logo',
                      width: 48,
                      height: 48,
                      className: `${styles.transferButton} ${
                        disableNextButton && styles.block
                      } `,
                    }}
                  />
                </CustomeLink>
              </div>
            )}
          </div>
        </div>
      </div>
      <DynamicFooterBanner />
      <DynamicFooter />
    </div>
  );
}

export default BlogContainer;
